export interface SerializedCloudEvent {
    type: string;
    time: string;
    specversion: string;
    source: string;
    id: string;
    datacontenttype: string;
    data: Record<string, string>;
}

export default class CloudEvent {
    private readonly _type: string;
    private readonly _time: Date;
    private readonly _specVersion: string;
    private readonly _source: string;
    private readonly _id: string;
    private readonly _dataContentType: string;
    private readonly _data: Record<string, string>;
    private readonly _raw: SerializedCloudEvent;

    constructor(event: SerializedCloudEvent) {
        this._type = event.type;
        this._time = new Date(event.time);
        this._specVersion = event.specversion;
        this._source = event.source;
        this._id = event.id;
        this._dataContentType = event.datacontenttype;
        this._data = event.data;
        this._raw = event;
    }

    get raw(): string {
        return JSON.stringify(this._raw, null, 4);
    }

    get type(): string {
        return this._type;
    }

    get time(): Date {
        return this._time;
    }

    get timeTimestamp(): number {
        return this.time.getTime();
    }

    get specVersion(): string {
        return this._specVersion;
    }

    get source(): string {
        return this._source;
    }

    get id(): string {
        return this._id;
    }

    get dataContentType(): string {
        return this._dataContentType;
    }

    get data(): Record<string, string> {
        return this._data;
    }
}
