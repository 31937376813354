























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import "simple-syntax-highlighter/dist/sshpre.css";
import CloudEvent, { SerializedCloudEvent } from "@/models/events";
import EventAPi from "@/services/api/event";
import { DatatableFooterProps, DefaultFooterProps } from "@/types/veutify";
import SearchInput from "@/components/SearchInput.vue";
import { ApiFilterOptions, ApiResponse } from "@/services/api/axios";
import { DataOptions } from "vuetify";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const SshPre = require("simple-syntax-highlighter");
@Component({
    components: {
        SearchInput,
        SshPre,
    },
})
export default class AdminEventsIndex extends Vue {
    loadingEvents: boolean = true;
    events: Array<CloudEvent> = [];
    query: string = "";
    datatableOptions: DataOptions = {
        page: 1,
        itemsPerPage: 50,
        sortBy: ["time"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
    };
    totalEvents: number = -1;
    footerProps: DatatableFooterProps = DefaultFooterProps;
    headers = [
        {
            text: "Event ID",
            value: "id",
        },
        {
            text: "Occurred At",
            value: "time",
        },
        {
            text: "Source",
            value: "source",
        },
        {
            text: "Type",
            value: "type",
        },
        {
            text: "Content Type",
            value: "dataContentType",
        },
        {
            text: "Spec Version",
            value: "specVersion",
        },
        { text: "Details", value: "data-table-expand" },
    ];

    get apiFilterOptions(): ApiFilterOptions {
        const headersMap = new Map<string, string>([
            ["time", "time"],
            ["source", "source"],
            ["type", "type"],
            ["specVersion", "specversion"],
            ["dataContentType", "datacontenttype"],
        ]);
        return {
            itemsPerPage: this.datatableOptions.itemsPerPage,
            page: this.datatableOptions.page,
            query: this.query,
            sortBy: headersMap.get(this.datatableOptions.sortBy[0]) ?? "time",
            sortDesc: this.datatableOptions.sortDesc[0],
        };
    }

    @Watch("datatableOptions")
    onOptionsChanged() {
        this.loadCloudEvents();
    }

    mounted() {
        this.loadCloudEvents();
    }

    onQueryChanged(query: string) {
        this.query = query;
        this.datatableOptions = {
            ...this.datatableOptions,
            page: 1,
        };
    }

    loadCloudEvents() {
        this.loadingEvents = true;
        EventAPi.adminIndex(this.apiFilterOptions)
            .then((response: AxiosResponse) => {
                this.events = response.data.data.items.map(
                    (event: SerializedCloudEvent) => {
                        return new CloudEvent(event);
                    }
                );
                this.totalEvents = response.data.data.total;
                this.loadingEvents = false;
            })
            .catch(this.handleAxiosError);
    }

    emitErrorNotification(error: Error) {
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.emitErrorNotification(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }
}
