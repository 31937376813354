import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,[_c(VRow,[_c(VCol,[_c('h2',{staticClass:"text-center text-h3"},[_vm._v("All Events")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,[_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6","offset-md":"6"}},[_c('search-input',{on:{"change":_vm.onQueryChanged}})],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"search":_vm.query,"loading-text":"Loading events...","items":_vm.events,"options":_vm.datatableOptions,"single-expand":true,"show-expand":"","server-items-length":_vm.totalEvents,"loading":_vm.loadingEvents,"item-key":"id","custom-filter":_vm.$constants.VUETIFY.CUSTOM_SEARCH,"items-per-page":_vm.footerProps.itemsPerPage,"footer-props":_vm.footerProps},on:{"update:items":function($event){_vm.events=$event},"update:options":function($event){_vm.datatableOptions=$event}},scopedSlots:_vm._u([{key:"item.time",fn:function(props){return [_vm._v(" "+_vm._s(_vm._f("dateTime")(props.item.time))+" ")]}},{key:"item.paymentAmount",fn:function(props){return [_vm._v(" "+_vm._s(_vm._f("currency")(props.item.paymentAmount))+" ")]}},{key:"item.orderStatus",fn:function(props){return [_c(VChip,{attrs:{"color":props.item.statusColor,"dark":""}},[_vm._v(" "+_vm._s(_vm._f("titleCase")(props.item.orderStatus))+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VSubheader,[_c('h6',{staticClass:"text-h6"},[_vm._v(" Data ")])]),_c(VSimpleTable,{staticClass:"mt-3",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Key ")]),_c('th',[_vm._v("Value")])])]),_c('tbody',_vm._l((item.data),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(" "+_vm._s(key)+" ")]),_c('td',[_vm._v(" "+_vm._s(value)+" ")])])}),0)]},proxy:true}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VSubheader,{staticClass:"mt-4 mb-2"},[_c('h6',{staticClass:"text-h6"},[_vm._v("Raw")])]),_c('ssh-pre',{attrs:{"dark":_vm.$vuetify.theme.dark,"language":"json"}},[_vm._v(_vm._s(item.raw))])],1)],1)],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }