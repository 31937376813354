import { AxiosResponse } from "axios";
import Axios, { ApiFilterOptions } from "./axios";

class Event {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    adminIndex(options: ApiFilterOptions): Promise<AxiosResponse> {
        return this.axios.get("/admin/events", {
            params: options,
        });
    }
}

export default new Event(Axios);
